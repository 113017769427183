import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/header'
import Footer from '../components/footer'
import { Link } from 'gatsby'
import Img1 from '../images/hero.png'

export default function Home(props) {
	return <>
		<Helmet>
			<title>Kelgrand - Apps for growing your business</title>
		</Helmet>

		<div style={{
			backgroundColor: '#F9E2BC',
			paddingBottom: '3rem',
		}}>
			<Header path={props.path} />
			<main className='container mt-5'>
				<div className='row'>
					<div className='col-sm-4'>
						<div className='subtext'>
							Apps to save time
						</div>
						<Link to='/windows' className='green-link' style={{marginTop: '1.3rem'}}>
							WINDOWS
						</Link>
						<div className='subtext'>
							PVC, Wood, Alu. Dealers &amp; Manufacturers.
						</div>
						<Link to='/internal-doors' className='green-link' style={{marginTop: '1.3rem'}}>
							INTERNAL DOORS
						</Link>
						<div className='subtext'>
							Beautiful looking quotes.
						</div>
					</div>
					<div className='col-sm-6 offset-sm-2'>
						<img src={Img1} style={{width:'100%'}} />
					</div>
				</div>
			</main>
		</div>

		<Footer />
	</>
}
